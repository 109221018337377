import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Co je evoluční biogeografie?" />
    <h1>Co je evoluční biogeografie?</h1>
    <p><strong>Evolučn&iacute; biogeografie </strong>popisuje a zkoum&aacute; evolučn&iacute; mechanismy zodpovědn&eacute; za &scaron;&iacute;řen&iacute; a distribuci organismů. Popisuje tak&eacute; evolučn&iacute; jevy vypl&yacute;vaj&iacute;c&iacute; např&iacute;klad z kontinent&aacute;ln&iacute;ho rozpadu či jednotliv&yacute;ch epizod d&aacute;lkov&eacute;ho pohybu organismů. Neodmyslitelnou souč&aacute;st&iacute; je pops&aacute;n&iacute; procesů vedouc&iacute; ke vzniku nov&yacute;ch druhů.</p>
    <hr />
    <h2>Podt&eacute;mata evolučn&iacute; biogeografie</h2>
    <p>Jelikož je pro evolučn&iacute; biogeografii kl&iacute;čov&eacute; hned několik, poměrně rozs&aacute;hl&yacute;ch t&eacute;mat, byly rozděleny do n&aacute;sleduj&iacute;c&iacute;ch samostatn&yacute;ch d&iacute;lč&iacute;ch kapitol:</p>
    <hr />

    <div className="od-sm">
    <hr />

<h3><Link to="/biogeografie/evolucni/koncepty-druhu/">Koncepty pojet&iacute; druhu</Link></h3>
<p>Druh lze definovat v biologii, potažmo biogeografii, popsat mnoha způsoby. Přibližme si tři nejdůležitěj&scaron;&iacute; koncepty.</p>
<hr />
<h3><Link to="/biogeografie/evolucni/speciace/">Speciace</Link></h3>
<p>Popi&scaron;me si z&aacute;kladn&iacute; mechanismy a způsoby, jak vznikaj&iacute; nov&eacute; druhy. Jde o p&aacute;teř evolučn&iacute; biogeografie!</p>
<hr />
<h3><Link to="/biogeografie/evolucni/makroevoluce/">Makroevoluce</Link></h3>
<p>Makroevoluce jako evolučn&iacute; proces ve vět&scaron;&iacute;m měř&iacute;tku! Rychl&yacute; a jednoduch&yacute; n&aacute;hled na hlavn&iacute; masov&aacute; vym&iacute;r&aacute;n&iacute;.</p>
<hr />
    </div>
    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Evoluční biogeografie</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/ekologicka/biogeograficka-pravidla/"><button className="inv">&larr; Biogeografická pravidla</button></Link>
    <Link to="/biogeografie/evolucni/koncepty-druhu/"><button className="inv">Koncepty pojetí druhu &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
